<template>
  <div class="table-home">
    <div class="col-12 text-center table-tit">CATEGORIAS AVALIADAS</div>
    <b-table class="pointer" :items="items" :fields="fields" :tbody-tr-class="setTbodyTrClass" @row-clicked="rowClickHandler">
        <template #cell(color)>
            <div class="legend"></div>
        </template>
        <template #cell(percent)="row">
                <div>{{formatNumber(row.item.percent,true)}}</div>
        </template>
    </b-table>
  </div>
</template>

<script>

import barramento from '@/barramento'

export default {
    props: ['items', 'rowHover'],
    data() {
        return {
            fields: [
                {
                    key: 'color',
                    label: '',
                    thStyle: {
                                width: '10%'
                            },
                    thClass: 'text-center',
                    tdClass: 'text-center'
                },
                {
                    key: 'category',
                    label: 'Categorias Avaliadas',
                    thStyle: {
                                width: '60%'
                            },
                    thClass: 'text-left activeBorder',
                    tdClass: 'text-left activeBorder'
                },
                {
                    key: 'total',
                    label: 'Espécies',
                    thStyle: {
                                width: '15%',
                            },
                    thClass: 'text-right activeBorder',
                    tdClass: 'text-right activeBorder'
                },
                {
                    key: 'percent',
                    label: '%',
                    thStyle: {
                                width: '15%'
                            },
                    thClass: 'text-right',
                    tdClass: 'text-right'
                }
            ],
            cat: {'EX': 127, 'EW': 128, 'RE': 129, 'CR': 130, 'EN': 131, 'VU': 132, 'NT': 133, 'LC': 134, 'DD': 135, 'NA': 136}
        }
    },
    methods: {
        setTbodyTrClass(item) {
            const classes = [];
            if(item) classes.push('custom-border');

            if(item.categoryCode == this.rowHover){
                classes.push('selectRow');
            }

            return classes;
        },
        rowClickHandler(record){
            var catCode = record.categoryCode
            var category = record.category
            var id = this.cat[catCode]
            var query = '/search?categoriaIds=' + id
            barramento.$emit('openModalR', {'query': query, 'items': {'categoriaIds': [id]}, 'filter': category})
        }
    }
  }
</script>

<style lang="scss">

    @import '@/web/scss/tables.scss';
    .pointer{
        cursor: pointer;
    }


    .selectRow {
        color: white;
        background-color: #208BCE;
        border: 5px solid #208BCE;
    }

</style>